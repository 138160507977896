<template>
  <div class="container" id="content">
    <div class="ctrolbtnBox">
      <el-button type="primary" size="small" @click="addOption">新增</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%; margin-bottom: 20px" row-key="id" border default-expand-all
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
      <el-table-column prop="name" label="名称" sortable width="180">
      </el-table-column>
      <el-table-column prop="capacity" label="总容量" sortable width="180">
      </el-table-column>
      <el-table-column prop="capacityUnit" label="容量单位"> </el-table-column>
      <el-table-column prop="capacityUsed" label="已用容量"> </el-table-column>
      <el-table-column prop="capacityUnused" label="剩余容量"> </el-table-column>
<!--      <el-table-column prop="type" label="仓库类型"> </el-table-column>-->
      <el-table-column prop="address" label="地址"> </el-table-column>
      <el-table-column prop="lxr" label="联系人"> </el-table-column>
      <el-table-column prop="lxfs" label="联系方式"> </el-table-column>
      <el-table-column prop="remark" label="备注"> </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="220" v-if="rowBtns.length">
        <template slot-scope="scope">
          <el-button v-for="(m, mindex) in rowBtns" :key="mindex" :type="m.type" :size="m.size" :icon="m.icon"
            @click="m.handler(scope.row)">
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <UpdataModal ref="updata" :messageBox="messageBox" :formTitle="formTitle" :openType="openType"
      :parentMess="parentMess" @handleClose="closeModal" @success="refresh" :record="addForm"></UpdataModal>
  </div>
</template>
<script>
import UpdataModal from "./modal/updata.vue";

export default {
  name: "lawsAReg",
  components: { UpdataModal },
  data() {
    return {
      loading: false,
      ids: 0,
      addForm: {},
      openType: 'add',
      tableData: [],
      parentMess: {},

      rowBtns: [
        {
          label: "新增",
          type: "primary",
          size: "small",
          icon: "el-icon-plus",
          handler: (row) => {
            this.addNew(row);
          },
        },
        {
          label: "编辑",
          type: "primary",
          size: "small",
          icon: "el-icon-edit",
          handler: (row) => {
            this.edits(row);
          },
        },
        {
          label: "删除",
          type: "danger",
          size: "small",
          icon: "el-icon-delete",
          handler: (row) => {
            this.deletes(row.id);
          },
        },
      ],

      formTitle: "",
      messageBox: false,
      token: "",
      filesList: [],
    };
  },
  mounted() {
    this.token = JSON.parse(window.localStorage.getItem("token"));
    this.init();
  },
  methods: {
    init() {
      this.$get(`/pro09/lengku/tree`).then((res) => { 
        if (res.data.state == "success") {
          this.tableData = res.data.data || [];
        } else {
          // this.$message.error("删除失败");
        }
      });
    },
    // 编辑
    edits(row) {
      this.openType = 'edit';
      this.ids = row.id;
      this.parentMess = {};
      this.formTitle = "编辑";
      this.addForm = JSON.parse(JSON.stringify(row));
      this.messageBox = true;
    },
    // 删除
    deletes(id) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$delete(`/pro09/lengku/delete/${id}`).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.init();
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch((e) => e);
    },
    // 新增
    addNew(row) {
      if (row.level == 3) {
        this.$message.error("该层级下不支持再次添加了");
        return;
      }
      this.openType = 'add'
      this.formTitle = "新增";
      this.parentMess = row;
      this.messageBox = true;
      this.$nextTick(() => {
        this.addForm = {};
        this.$refs.updata.resetForm();
      });
    },
    // 新增
    addOption() {
      this.openType = 'add'
      this.formTitle = "新增";
      this.parentMess = {};
      this.messageBox = true;

      this.$nextTick(() => {
        this.addForm = {};
        this.$refs.updata.resetForm();
      });
    },

    refresh() {
      this.messageBox = false;
      this.init();
    },

    closeModal() {
      this.messageBox = false;
    },
  },
};
</script>

<style scoped>
.ctrolbtnBox {
  width: 100%;
  display: flex;
  flex-flow: row;
  padding-bottom: 10px;
}
</style>
